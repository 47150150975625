import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Form,
  Input,
  FormAction,
  Button,
  Text,
  Select,
  Link,
  Icon,
  IconButton,
  Divider
} from '../..'
import { SelectWithSearch } from '../SelectWithSearch'

export class RmYourInfoCard extends React.PureComponent {
  static propTypes = {
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    onFormSubmit: PropTypes.func,
    /**
     * First name
     */
    firstNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Last name
     */
    lastNameInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Country
     */
    countrySelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),
    /**
     * Zip Code
     */
    zipInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * Zip Code
     */
    streetAddressInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    /**
     * City
     */
    cityInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),
    /**
     * State
     */
    stateSelectFieldProps: PropTypes.shape({
      options: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.string
        })
      ),
      value: PropTypes.string,
      onChange: PropTypes.func,
      disabled: PropTypes.bool,

      id: PropTypes.string
    }),

    /**
     * phoneNumberFields
     */
    phoneNumberFieldsProps: PropTypes.arrayOf(
      PropTypes.shape({
        selectPhoneFieldProps: PropTypes.shape({
          options: PropTypes.arrayOf(
            PropTypes.shape({
              value: PropTypes.string,
              label: PropTypes.string
            })
          ),
          value: PropTypes.string,
          onChange: PropTypes.func,
          disabled: PropTypes.bool,

          id: PropTypes.string
        }),
        inputPhoneFieldProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        })
      })
    ),
    /**
     * Email address
     */
    emailInputFieldProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    submitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),
    showCancelButton: PropTypes.bool,
    cancelButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    }),

    linkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    phoneLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    showAddContactLink: PropTypes.bool,
    closeContactProps: PropTypes.func,
    closePhoneNumberProps: PropTypes.func,
    emergencyContactFields: PropTypes.arrayOf(
      PropTypes.shape({
        firstNameInputProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        }),
        lastNameInputProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        }),
        phoneInputFieldProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        }),
        emailInputFieldProps: PropTypes.shape({
          value: PropTypes.string,
          onChange: PropTypes.func,
          onBlur: PropTypes.func,
          onFocus: PropTypes.func,
          name: PropTypes.string,
          error: PropTypes.bool,
          hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
          id: PropTypes.string
        })
      })
    ),
    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ])
  }
  static defaultProps = {
    emergencyContactFields: [],
    showAddContactLink: true,
  }

  render() {
    const {
      successMessage,
      errorMessage,
      onFormSubmit,
      emailInputFieldProps,
      firstNameInputFieldProps,
      lastNameInputFieldProps,
      cancelButtonProps,
      showCancelButton,
      countrySelectFieldProps,
      cityInputFieldProps,
      stateSelectFieldProps,
      phoneNumberFieldsProps,
      zipInputFieldProps,
      submitButtonProps,
      streetAddressInputFieldProps,
      linkProps,
      phoneLinkProps,
      showAddContactLink,
      emergencyContactFields,
      closeContactProps,
      closePhoneNumberProps,
      id,
      className,
      style,
      refProp
    } = this.props

    return (
      <Paper
        id={id}
        className={cn('ui-user-contact-info-card', className)}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>Your Information</PaperHeadline>
        <PaperContent>
          <Text type={Text.Types.body} className='sub-heading'>
            Please enter your information below.
          </Text>

          <Form onSubmit={onFormSubmit} className='c-form'>
            {successMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__success-message'
              >
                {successMessage}
              </Text>
            )}
            {errorMessage && (
              <Text
                type={Text.Types.bodyBold}
                className='ui-user-contact-info-card__error-message'
              >
                {errorMessage}
              </Text>
            )}
            <Input
              label='First Name*'
              {...firstNameInputFieldProps}
            />

            <Input label='Last Name*' {...lastNameInputFieldProps} />

            <Select
              label='Country*'
              helper='If you don’t see it in the list below, select “Other” at the very bottom.'
              {...countrySelectFieldProps}
            />
            <Input
              label='Zip Code/Postal Code*'
              {...zipInputFieldProps}
            />
            <Input
              label='Street Address*'
              {...streetAddressInputFieldProps}
            />
            <Input label='City*' {...cityInputFieldProps} />
            <SelectWithSearch
              helper='If you don’t see it in the list below, select “Other” at the very bottom.'
              label='State/Province*'
              {...stateSelectFieldProps}
              searchable
            />
            
             

            {phoneNumberFieldsProps.map((fieldValue, index) => {
              return (
                <div key={index}>
                  {index > 0 && <Divider className='ui-emergency-contact-card__divider' />}
                  {index === 0 ? (
                    <>
                      <label
                        htmlFor={
                          index === 0
                            ? 'phoneNumberrow'
                            : `phoneNumberrow${index}`
                        }
                      >
                        Phone number{index === 0 ? '' : index + 1}
                        {index === 0 ? '*' : ''}
                      </label>
                    </>
                  ) : (
                    <div className='ui-your-contact-phone-container'>
                      <label
                        htmlFor={
                          index === 0
                            ? 'phoneNumberrow'
                            : `phoneNumberrow${index}`
                        }
                      >
                        Phone number {index === 0 ? '' : index + 1}
                        {index === 0 ? '*' : ''}
                      </label>
                      <div className='ui-your-contact-phone-close-icon'>
                        <IconButton
                          id={`closePhoneId${index}`}
                          type='close' 
                          onClick={() => closePhoneNumberProps(index)}
                        />
                      </div>
                    </div>
                  )}
                  <div
                    className='ui-your-contact-info-card__form__row'
                    id='phoneNumberrow'
                  >
                    {/* <Select {...fieldValue.selectPhoneFieldProps} /> */}
                    <Input id={`phoneId${index}`} {...fieldValue.inputPhoneFieldProps} />
                  </div>
                </div>
              )
            })}
            {phoneNumberFieldsProps.length <=2 && <Link { ...phoneLinkProps }>
              <Icon
                type='add_circle'
                className='ui-emergency-contact-card__icon'
              />
              <span className='ui-emergency-contact-card__span' id="addAnotherPhoneId">
                Add another phone number
              </span>
            </Link> }

            <Input
              label='Email Address*'
              {...emailInputFieldProps}
            />
            {emergencyContactFields.map((fieldValue, index) => (
              <div key={index} className='ui-emergency-contact-card__inputs'>
                <Divider className='ui-emergency-contact-card__divider' />
                <div
                  role='button'
                  className='ui-emergency-contact-card__header'
                >
                  <Text className='ui-emergency-contact-card__text'>
                    Emergency contact {index === 0 ? '' : index + 1} info
                  </Text>
                  <IconButton
                    id={`closeECId${index}`}
                    type='close'
                    onClick={() => closeContactProps(index)}
                  />
                </div>

                <Input
                  id={`firstNameECId${index}`}
                  label='First Name*'
                  placeholder='Example: John'
                  autoComplete='given-name'
                  {...fieldValue.firstNameInputProps}
                />

                <Input
                  id={`lastNameECId${index}`}
                  label='Last Name*'
                  placeholder='Example: Smith'
                  autoComplete='family-name'
                  {...fieldValue.lastNameInputProps}
                />
                <Input
                  id={`phoneECId${index}`}
                  label='Primary Phone Number*'
                  placeholder='(###) ### ####'
                  {...fieldValue.phoneInputFieldProps}
                />
                <Input
                  id={`emailECId${index}`}
                  label='Email Address*'
                  placeholder='Example: yourname@email.com'
                  {...fieldValue.emailInputFieldProps}
                />
              </div>
            ))}
            {showAddContactLink && (
              <Link {...linkProps}>
                <Icon
                  type='add_circle'
                  className='ui-emergency-contact-card__icon'
                />
                <span className='ui-emergency-contact-card__span' id='addAnotherECId'>
                  {' '}
                  {emergencyContactFields.length == 0
                    ? 'Add an emergency contact'
                    : 'Add another emergency contact'}
                </span>
              </Link>
            )}
            <FormAction>
              {showCancelButton && (
                <Button
                  id='cancelButtonId'
                  className='rm-ui-your-info-card-action-button'
                  color={Button.Colors.secondary}
                  variant={Button.Variants.outlined}
                  {...cancelButtonProps}
                >
                  Cancel
                </Button>
              )}
              <Button
                id='continueButtonId'
                color={Button.Colors.tertiary}
                type='submit'
                {...submitButtonProps}
              >
                Continue
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}
