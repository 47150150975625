import React from 'react'
import {PageContent, RmReviewCard, Steps} from "../../../UI-Component-Library";
import { DataLayerUtils } from '../../../utils/dataLayer-utils';
import { AppUtils } from '../../../utils/app-utils';
class RegistrationReview extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
             
        }
    }

    componentDidMount() {
      window.scrollTo(0, 0);
    }

    getCountryName () {

      if (this.props.formValues.country?.toLowerCase() === "ca") {
        return "Canada"
      }

      if (this.props.formValues.country?.toLowerCase() === "us") {
          return "United States";
      }

      return "N/A";
    }

    getDisplayValue (value) {
      if (value) {
        const vv = `${value}`;
        if(vv.length > 0) {
          return value;
        }
      }
      return "N/A";
    }

    getMonthValue () {
      let month = `${this.props.formValues.birthMonth}`;
      if (month && month.length === 1) {
        const newMonth = `0${month}`;
        return newMonth
      }
      return month;
    }

    getFormattedPhone (value) {

      if (value && value.length > 0) {
        const numbers = value.split('');
        const three = `(${numbers[0]}${numbers[1]}${numbers[2]})`;
        const four = `${numbers[3]}${numbers[4]}${numbers[5]}`;
        const five = `${numbers[6]}${numbers[7]}${numbers[8]}${numbers[9]}`;
        const final = `${three}${four}-${five}`;
        return final;
      } else {
        return '';
      }
    }

    getValueforEC = (firstName, lastName, Email, Phone) =>{
      let formattedPhone = this.getFormattedPhone(Phone)
      return <><div>{firstName} {lastName}</div><div>{Email}</div><div>{formattedPhone}</div></>
    }

    getPhoneNumberValues = (type, phone) => {
      if (phone === "") {
        return "N/A";
      }
      phone = phone.replaceAll("-", "");
      let formattedPhone = this.getFormattedPhone(phone);
      return (<>
        {/* <div>{type}</div> */}
        <div>{formattedPhone}</div>
      </>)
    }

    getYourInformationForReview = () => {
      let yourInformation,
          emergencyContactValues;

      yourInformation=[
        {
          key: "First Name",
          value: this.getDisplayValue(this.props.formValues.firstName),
        },
        {
          key: "Last Name",
          value: this.getDisplayValue(this.props.formValues.lastName),
        },
        {
          key: "Country",
          value: this.getCountryName(),
        },
        {
          key: "Zip Code/ Postal Code",
          value: this.getDisplayValue(this.props.formValues.zipCode),
        },
        {
          key: "Street Address",
          value: this.getDisplayValue(this.props.formValues.street),
        },
        {
          key: "City",
          value: this.getDisplayValue(this.props.formValues.city),
        },
        {
          key: "State/Province",
          value: this.getDisplayValue(this.props.formValues.state),
        },
        {
          key: "Phone Number 1",
          value: this.getPhoneNumberValues("Home", this.props.formValues.primaryPhone),
        },
        {
          key: "Phone Number 2",
          value: this.getPhoneNumberValues("Mobile", this.props.formValues.mobilePhone),
        },
        {
          key: "Phone Number 3",
          value: this.getPhoneNumberValues("Work", this.props.formValues.businessPhone),
        },
        {
          key: "Email Address",
          value: this.getDisplayValue(this.props.formValues.email),
        },
      ];

      emergencyContactValues=this.props.formValues.emergencyContactValues

      if(emergencyContactValues.length === 0){
        return yourInformation;
      }

      emergencyContactValues.forEach( (value, index) => {
         let ec={ key: `Emergency Contact ${index + 1} Info`};
         ec["value"]=this.getValueforEC(value.FirstName, value.LastName, value.EmailAddress, value.HomePhone);
         yourInformation.push(ec);
      })
      
      return yourInformation;
  }
  
  getPetInfoForReview = () => {
    const isPhone = AppUtils.isEqualString(this.props.type, 'phone');
    let petInfo = [
      {
        key: "Microchip Number",
        value: this.getDisplayValue(
          this.props.formValues.microChipNumber
        ),
        notShowMe: isPhone, 
      },
      {
        key: "Microchip Provider",
        value: this.getDisplayValue(
          this.props.formValues.microchipProviderName
        ),
        notShowMe: isPhone, 
      },
      {
        key: "Pet Name",
        value: this.getDisplayValue(this.props.formValues.petName),
      },
      {
        key: "Species",
        value: this.getDisplayValue(this.props.formValues.petType),
      },
      {
        key: "Gender",
        value: this.getDisplayValue(this.props.formValues.petGender),
      },
      {
        key: "Breed Type",
        value: this.getDisplayValue(this.props.formValues.breedType),
      },
      {
        key: "Breed Name",
        value: this.getDisplayValue(
          this.props.formValues.petBreedLabel
        ),
      },
      {
        key: "Color",
        value: this.getDisplayValue(this.props.formValues.colorLabel),
      },
      {
        key: "Spayed or Neutered",
        value: this.getDisplayValue(this.props.formValues.isSprayed),
      },
      {
        key: "Birth Month and Year",
        value: `${this.getDisplayValue(
          this.getMonthValue()
        )}/${this.getDisplayValue(this.props.formValues.birthYear)}`,
      },
      {
        key: "Clinic Where Pet Was Microchipped",
        value: this.getDisplayValue(
          this.props.formValues.clinicInfoLabel
        ),
        notShowMe: isPhone, 
      },
      {
        key: "Consent to Release Contact Info When Pet Is Found",
        value: this.getDisplayValue(this.props.formValues.isConsent),
      },
    ];

    return petInfo;
  }

    getButtonState = () => {
      if ((this.props.formValues.isYourInfoSubmitted) && (this.props.formValues.isPetInfoSubmitted)) {
        return false
      }
      return true;
    }
    render() {
      const yourInformation = this.getYourInformationForReview();
      const petInformation = this.getPetInfoForReview();
      return (
        <PageContent>
          <Steps
            showSteps={true}
            steps={[
              {
                label: "Your Information",
                active: false,
                completed: true,
                onClick: (event) => {
                  this.props.onJumpPage(1);
                },
              },
              {
                label: "Pet Information",
                active: false,
                completed: true,
                onClick: (event) => {
                  this.props.onJumpPage(2);
                },
              },
              {
                label: "Review & Sumbit",
                active: true,
                onClick: (event) => {
                  this.props.onJumpPage(3);
                },
              },
            ]}
          />
          <RmReviewCard
            type={this.props.type}
            yourInformation={yourInformation}
            petInformation={petInformation}
            petInfoEditLinkProps={{
              id:"petInfoEditId",
              onClick: (event) => {
                DataLayerUtils.createAccountEvent(
                  this.props.type,
                  'edit pet info',
                  'organic',
                  this.props.formValues.microChipNumber
                )
                this.props.onEditButtonClicked(2);
              },
            }}
            yourInfoEditLinkProps={{
              id:"ownerInfoEditId",
              onClick: (event) => {
                DataLayerUtils.createAccountEvent(
                  this.props.type,
                  'edit your info',
                  'organic',
                  this.props.formValues.microChipNumber
                )
                this.props.onEditButtonClicked(1);
              },
            }}
            errorMessage={this.props.formValues.reviewPageError}
            confirmAndSubmitButtonProps={{
              disabled: this.getButtonState(),
              id: "petReviewSubmitId",
              onClick: (event) => {
                this.props.clickOnSubmit();
              },
            }}
          />
        </PageContent>
      );
    }
}

export default RegistrationReview;