import React from "react";
import PropTypes from "prop-types";
import cn from "classnames";

import {
  Paper,
  PaperContent,
  PaperHeadline,
  Text,
  Form,
  Input,
  Button,
  Link,
  FormAction,
  Select,
} from "../../";

export class CreateAccountCard extends React.PureComponent {
  static Types = Object.freeze({
    microchip: "microchip",
    policy: "policy",
    phone: "phone"
  });

  static propTypes = {
    type: PropTypes.oneOf(Object.values(CreateAccountCard.Types)),

    policyNumberInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    firstNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    lastNameInputProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    firstPhoneNumberProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    secondPhoneNumberProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    thirdPhoneNumberProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string,
    }),

    emailAddressProps: PropTypes.shape({
      value: PropTypes.string,
      onChange: PropTypes.func,
      onBlur: PropTypes.func,
      onFocus: PropTypes.func,
      name: PropTypes.string,
      error: PropTypes.bool,
      hint: PropTypes.oneOfType([PropTypes.array, PropTypes.string]),
      id: PropTypes.string
    }),

    verbiage: PropTypes.string,
    verbiage2: PropTypes.string,

    createAccountButtonProps: PropTypes.shape({
      disabled: PropTypes.bool,
      onClick: PropTypes.func,
      id: PropTypes.string,
    }),
    onClickToCall: PropTypes.func,

    onFormSubmit: PropTypes.func,

    errorMessage: PropTypes.string,

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    phoneNumber: PropTypes.string,
  };

  static defaultProps = {
    type: CreateAccountCard.Types.microchip,
  };

  render() {
    const {
      type,
      onClickToCall,
      policyNumberInputProps,
      firstNameInputProps,
      lastNameInputProps,
      verbiage,
      firstPhoneNumberProps,
      secondPhoneNumberProps,
      thirdPhoneNumberProps,
      emailAddressProps,
      verbiage2,
      createAccountButtonProps,
      onFormSubmit,
      errorMessage,
      phoneNumber,
      id,
      className,
      style,
    } = this.props;

    const isPolicy = type === CreateAccountCard.Types.policy;
    const isPhone = type === CreateAccountCard.Types.phone;

    return (
      <div
        id={id}
        className={cn("ui-create-account-card__paper", className)}
        style={style}
      >
        <Paper type={Paper.Types.framed}>
          <PaperHeadline>Create an account</PaperHeadline>
          <PaperContent className="ui-create-account-card__content">
            {!isPhone && (<Text>
              To successfully create an account please have your{" "}
              {isPolicy ? "Policy" : "Microchip"} number available. If you don’t
              have a {isPolicy ? "policy" : "microchip"} number, please call
              {"\u00A0"}
              <Link href={"tel:" + phoneNumber} onClick={onClickToCall}>
                {phoneNumber}
              </Link>
              .
            </Text>)}
            {isPhone && (
              <Text className="ui-create-account-card__text">
                Enter your name and phone number to create a MyPethealth.com account.
              </Text>
            )}

            {errorMessage && (
              <Text
                className="ui-create-account-card__error-message"
                type={Text.Types.bodyBold}
              >
                {errorMessage}
              </Text>
            )}

            <Form
              className="ui-create-account-card__form"
              onSubmit={onFormSubmit}
            >
              {!isPhone && (<Input
                label={`${isPolicy ? "Policy" : "Microchip"} Number (required)`}
                placeholder={`${isPolicy ? "Policy" : "Microchip"} Number`}
                {...policyNumberInputProps}
              />)}

              <div className="ui-create-account-card__horizontal-row">
                <Input
                  label="First Name*"
                  placeholder="First Name"
                  autoComplete="given-name"
                  {...firstNameInputProps}
                />

                <Input
                  label="Last Name*"
                  placeholder="Last Name"
                  autoComplete="family-name"
                  {...lastNameInputProps}
                />
              </div>

              {isPhone && (<>
                {/* <label className="ui-create-account-card-label">Phone Number*</label> */}
                <Input
                  label="Email Address*"
                  placeholder="example@gmail.com"
                  {...emailAddressProps}
                />
                <Input
                  label="Phone Number*"
                  placeholder="555-555-1234"
                  {...firstPhoneNumberProps}
                />
                {/* <Input
                  label="Mobile Phone"
                  placeholder="Mobile Phone"
                  {...secondPhoneNumberProps}
                />
                <Input
                  label="Work Phone"
                  placeholder="Work Phone"
                  {...thirdPhoneNumberProps}
                /> */}
              </>)}

              {verbiage && <Text>{verbiage}</Text>}
              {(isPhone && verbiage2) && <Text className="ui-create-account-card-verbiage">{verbiage2}</Text>}
              <FormAction>
                <Button
                  className='ui-create-account-card-button'
                  color={Button.Colors.tertiary}
                  type="submit"
                  {...createAccountButtonProps}
                >
                  {isPhone ? 'Continue' : 'Create a new account'}
                </Button>
              </FormAction>
            </Form>
          </PaperContent>
        </Paper>
      </div>
    );
  }
}
