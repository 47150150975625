import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import { Paper, PaperContent, PaperHeadline } from '../..'
import { HeaderDivider } from '../HeaderDivider'
import { Table, TableBody, TableDataCell, TableRow } from '../TableComponents'
import { Link } from '../Link'
import { Form, FormAction } from '../Form'
import { Button } from '../Button'

export class RmReviewCard extends React.PureComponent {
  static propTypes = {
    pageHeadLine: PropTypes.string,
    type: PropTypes.string,
    yourInformation: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
      })
    ),

    petInformation: PropTypes.arrayOf(
      PropTypes.shape({
        key: PropTypes.string,
        value: PropTypes.string
      })
    ),
    yourInfoEditLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),
    petInfoEditLinkProps: PropTypes.shape({
      href: PropTypes.string,
      target: PropTypes.string,
      id: PropTypes.string
    }),

    id: PropTypes.string,
    className: PropTypes.string,
    style: PropTypes.object,
    refProp: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.shape({ current: PropTypes.instanceOf(Element) })
    ]),
    confirmAndSubmitButtonProps: PropTypes.shape({
      id: PropTypes.string,
      onClick: PropTypes.func
    })
  }

  static defaultProps = {
    recoveryHistory: [],
    claimHistory: []
  }

  render() {
    const {
      yourInformation,
      yourInfoEditLinkProps,
      petInformation,
      petInfoEditLinkProps,
      confirmAndSubmitButtonProps,
      pageHeadLine,
      id,
      className,
      style,
      refProp,
      type
    } = this.props

    const isYourInformationEmpty = Object.keys(yourInformation).length === 0;
    const isPetInformationEmpty = Object.keys(petInformation).length === 0;
    const isPhone = type?.toLowerCase() === 'phone';
    return (
      <Paper
        id={id}
        className={cn(
          {
            'ui-cr-history--hidden':
              isYourInformationEmpty && isPetInformationEmpty
          },
          className
        )}
        style={style}
        ref={refProp}
        type={Paper.Types.framed}
      >
        <PaperHeadline>{pageHeadLine ? pageHeadLine : 'Review & Submit'}</PaperHeadline>
        <PaperContent>
          <Form>
            <div
              className={cn('ui-cr-history__item', {
                'ui-cr-history__item--hidden': isYourInformationEmpty
              })}
            >
              <div className='flex-header'>
                <div></div>
                <HeaderDivider>Your Information</HeaderDivider>
                <Link className='ui-rm-review-edit' color={Link.Colors.secondary} {...yourInfoEditLinkProps}>
                  Edit
                </Link>
              </div>

              <div className='ui-rm-review'>
                <Table>
                  <TableBody>
                    {yourInformation.map((info, index) => {
                      return (
                        <TableRow
                          key={`${info.key}-${info.value}`}
                          className={
                            (info.key === "Emergency Contact 1 Info"|| info.key === "Emergency Contact 2 Info" )
                            ? 'ui-cr-history__cell ui-cr-history__table-row'
                            : 'ui-cr-history__table-row'
                          }
                        >
                          <TableDataCell>{info.key}</TableDataCell>
                          <TableDataCell id={`petReviewOwnerInfo${index}`}>{info.value}</TableDataCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>

            <div
              className={cn('ui-cr-history__item', {
                'ui-cr-history__item--hidden': isPetInformationEmpty
              })}
            >
              <div className='flex-header'>
                <div></div>
                <HeaderDivider>Pet Information</HeaderDivider>
                <Link className='ui-rm-review-edit' color={Link.Colors.secondary} {...petInfoEditLinkProps}>
                  Edit
                </Link>
              </div>

              <div className='ui-rm-review'>
                <Table>
                  <TableBody>
                    {petInformation.map((info, index) => {
                      if (info?.notShowMe) return;
                      return (
                        <TableRow
                          key={`${info.key}-${info.value}`}
                          className='ui-cr-history__table-row'
                        >
                          <TableDataCell>{info.key}</TableDataCell>
                          <TableDataCell id={`petReviewPetInfo${index}`}>{info.value}</TableDataCell>
                        </TableRow>
                      )
                    })}
                  </TableBody>
                </Table>
              </div>
            </div>
            <FormAction>
              <Button
                color={Button.Colors.tertiary}
                type='submit'
                {...confirmAndSubmitButtonProps}
              >
                Create Account
              </Button>
            </FormAction>
          </Form>
        </PaperContent>
      </Paper>
    )
  }
}
