import React from "react";
import RegistrationStep1 from "./registeration-step-one";
import RegistrationStep2 from "./registeration-step-two";
import RegistrationReview from "./registration-review";
import RegistrationSuccess from "./registertaion-success";
import { ValidationMessages } from '../../../utils/validation-messages';
import axios from "../../../custom-axios";
import { ApiConstants } from "../../../utils/api-constants";
import {
  RmStep1Card,
  TwoColumnContent,
  RmStep1InfoCard,
  RmPageHeader,
  PageContent,
  RmPageFooter,
  InputHelperWithTooltip,
  EnterEmailCard,
} from "../../../UI-Component-Library";
import { LoadMask } from "../../../utils/loadmask.util";
import { AppUtils } from "../../../utils/app-utils";
import { FeatureFlagUtils } from "../../../utils/feature-flag-utils";
import { DataLayerUtils } from "../../../utils/dataLayer-utils";
import { AppConstants } from "../../../utils/app-constants";

class RegisterMicrochipContainer extends React.Component {
  constructor(props) {
    super(props);

    this.regValidRef = React.createRef();
    // let storedStep = localStorage.getItem("step");

    // if (!storedStep) {
    //   storedStep = 0;
    //   localStorage.setItem("step", 0);
    // }

    this.state = {
      step: 0,
      firstName: this.props.location.state.firstName, // Step1
      lastName: this.props.location.state.lastName,
      country: "",
      zipCode: "",
      street: "",
      city: "",
      state: "",
      primaryPhone: "",
      businessPhone: "",
      mobilePhone: "",
      phoneNumbers: [],
      email: this.props.location.state?.emailAddress || "",
      microChipNumber: this.props.location.state.microchipNumber, // Step2
      microchipProvider: 0,
      petName: "",
      petType: "",
      petGender: "",
      petBreed: "",
      petColor: "",
      isSprayed: "",
      birthMonth: "",
      birthYear: "",
      clinicInfo: "",
      isConsent: "",
      breedType: "",
      chipProviders: "",
      chipValidationError: "", // Chip Validation Error
      ownerErrorMessage: "",
      breedsList: [],
      petBreedLabel: "",
      clinicInfoLabel: "",
      colorLabel: "",
      canadaClinicList: [],
      usClinicList: [],
      canadaColorList: [],
      usColorList: [],
      isShowCancelStep1: false,
      isShowCancelStep2: false,
      showSteps: true,
      showLink: false,
      infoStart: "",
      savePetErrorMessage: "",
      reviewPageError: "",
      microchipProviderName: "",
      isEditClicked: false,
      isYourInfoSubmitted: false,
      isPetInfoSubmitted: false,
      ownerInfoSaved: false,
      emergencyContacts: [],
      emergencyContactValues: [],
      isShowEmailError:false,
      emailErrorMessage: "",
      newMphRegistrationId: "",
    };
    this.updateEmergencyContacts = this.updateEmergencyContacts.bind(this);
    this.updatePhoneNumbers = this.updatePhoneNumbers.bind(this);
  }

  updateEmergencyContacts = (contacts) => {
    let updatedEmergencyContacts = [...contacts]
    this.setState({
      emergencyContacts: updatedEmergencyContacts,
    })
  }
  updatePhoneNumbers = (numbers) => {
    let updatedPhoneNumbers = [...numbers]
    this.setState({
      phoneNumbers: updatedPhoneNumbers,
    })
  }

  checkValidData(value, field){
    if(!AppUtils.isEmptyString(value)){
      if(field==="phone number" && !AppUtils.isValidPhoneNumber(value)){
       return {
         error: !AppUtils.isValidPhoneNumber(value),
         hint:ValidationMessages.INVALID_PHONE_NUMBER
       }
      }
     if(field === "email address" && !AppUtils.isValidEmail(value)){
       return {
         error: !AppUtils.isValidEmail(value),
         hint:ValidationMessages.EMAIL_VALIDATION_MESSAGE
       }
     }
     return {
       error:false,
       hint:""
     }
    }else{
      return {
        error:true,
        hint:`Please fill out your ${field} before continuing.`
      }
    }
 }
  updateContactFields(updateEmergencyContactFields, firstName, lastName, phone, email, index){
    updateEmergencyContactFields[index].firstNameInputProps.error=firstName.error;
    updateEmergencyContactFields[index].firstNameInputProps.hint = firstName.hint;
    updateEmergencyContactFields[index].lastNameInputProps.error=lastName.error;
    updateEmergencyContactFields[index].lastNameInputProps.hint=lastName.hint;
    updateEmergencyContactFields[index].phoneInputFieldProps.error=phone.error;
    updateEmergencyContactFields[index].phoneInputFieldProps.hint=phone.hint;
    updateEmergencyContactFields[index].emailInputFieldProps.error=email.error;
    updateEmergencyContactFields[index].emailInputFieldProps.hint=email.hint;

    return updateEmergencyContactFields;
  }

  validateEmergencyContactFieldValues(){
    let payload = [],
        error = false,
        updateEmergencyContactFields = this.state.emergencyContacts;

    this.state.emergencyContacts.forEach((contact,index) => {
      let firstName,
          lastName,
          phone,
          email;
      firstName = this.checkValidData(contact.firstNameInputProps.value, "first name");
      lastName = this.checkValidData(contact.lastNameInputProps.value, "last name");
      phone = this.checkValidData(contact.phoneInputFieldProps.value, "phone number");
      email = this.checkValidData(contact.emailInputFieldProps.value, "email address");

      if(firstName.error || lastName.error || phone.error || email.error){
        error=true;
      }

      updateEmergencyContactFields = this.updateContactFields(updateEmergencyContactFields, firstName, lastName, phone, email, index)

      payload.push({
        "FirstName": contact.firstNameInputProps.value,
        "LastName": contact.lastNameInputProps.value,
        "EmailAddress": contact.emailInputFieldProps.value,
        "HomePhone": contact.phoneInputFieldProps.value,
        "MobilePhone":"",
        "BusinessPhone":""
      })
    })

    if(error){
      this.setState({
        emergencyContacts:updateEmergencyContactFields
      })
      return -1;
    }else{
      return payload;
    }
  }


  getPhoneNumbers(payload) {
    let home = "",
        mobile = "",
        work = "";
    for (let number of payload) {
      if (number.value && number.type === "Home" ) home = number.value;
      if (number.value && number.type === "Mobile" ) mobile = number.value;
      if (number.value && number.type === "Work") work = number.value;
    }

    return {
      home: home,
      mobile: mobile,
      work: work,
    }
  }

  checkValidPhoneNumber(phoneType, value, index) {
    if (!AppUtils.isEmptyString(value)) {
      if (!AppUtils.isValidPhoneNumber(value)) {
        return {
          error: !AppUtils.isValidPhoneNumber(value),
          hint: ValidationMessages.INVALID_PHONE_NUMBER
        }
      }
      return {
        error: false,
        hint: (phoneType === "Home")
        ? "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
        : "",
      }
    }

    if (index === 0) {
      return {
        error:true,
        hint:`Please fill out your phone number before continuing.`
      }
    }
    return {
      error: false,
      hint: (phoneType === "Home")
      ? "You’re not required to own a ‘Home’ phone, however if it’s left blank, our system will auto-populate this field with your mobile or work number.  This will not impact our ability to contact you if your pet is lost and found."
      : "",
    }
  }
  validatePhoneNumberValues(){
    let phoneError = false,
        phoneNumberPayload = [],
        updatePhoneNumberFields = this.state.phoneNumbers;

    this.state.phoneNumbers.forEach((number, index) => {
      let showError = this.checkValidPhoneNumber(number.selectPhoneFieldProps.value, number.inputPhoneFieldProps.value, index);

      if (showError.error) {
        phoneError = true;
      }
      updatePhoneNumberFields[index].inputPhoneFieldProps.error = showError.error;
      updatePhoneNumberFields[index].inputPhoneFieldProps.hint = showError.hint;
      phoneNumberPayload.push({
        type: number.selectPhoneFieldProps.value,
        value: number.inputPhoneFieldProps.value,
      })
    })

    if (phoneError) {
      this.setState({
        phoneNumbers: updatePhoneNumberFields
      })
      return -1;
    } else {
      return this.getPhoneNumbers(phoneNumberPayload);
    }
  }

  saveOwnerRegistrationInfo = () => {
    let emergencyContacts = this.validateEmergencyContactFieldValues();
    let phoneNumbers = this.validatePhoneNumberValues();
    if(emergencyContacts === -1 || phoneNumbers === -1){
      return;
    }

    let primaryPhoneNumber = (phoneNumbers.home)
      ? phoneNumbers.home
      : (phoneNumbers.mobile) ? phoneNumbers.mobile : phoneNumbers.work;
    this.setState({
      emergencyContactValues : emergencyContacts,
      primaryPhone: phoneNumbers.home,
      mobilePhone: phoneNumbers.mobile,
      businessPhone: phoneNumbers.work,
    })
    window.qasVerify(true);
    window.qas_callbackFunc = (address) => {
      if (address) {
        if (this.state.ownerInfoSaved) {
          return;
        }
        this.setState(
          {
            city: address.city,
            state: address.region,
            zipCode: address.postcode,
            street: address.street.join(" "),
            ownerInfoSaved: true,
          },
          () => {
            const ownerInfo = {
              firstName: this.state.firstName,
              lastName: this.state.lastName,
              country: this.state.country,
              zipCode: this.state.zipCode,
              street: this.state.street,
              city: this.state.city,
              state: this.state.state,
              homePhone: primaryPhoneNumber,
              cellularPhone : phoneNumbers.mobile,
              businessPhone: phoneNumbers.work,
              emailAddress: this.state.email,
              microchipNumber: this.state.microChipNumber,
              EmergencyContacts: emergencyContacts,
              isNewUser: true,
            };

            LoadMask.showPreloader(this.refs.ownerReg);
            axios
              .processPost(
                `${ApiConstants.URL_MICRO_SAVE_OWNER_REG}`,
                null,
                ownerInfo
              )
              .then((response) => {
                LoadMask.hidePreloader(this.refs.ownerReg);
                this.setState({
                  isYourInfoSubmitted: true,
                  ownerInfoSaved: false,
                  newMphRegistrationId: response?.newMphRegId,
                });
                this.clickNext();
                DataLayerUtils.createAccountEvent(
                  this.props.location.state.type,
                  'submit your info',
                  'organic',
                  this.state.microChipNumber
                )
              })
              .catch((error) => {
                let errors = error?.response?.data?.errors;
                if (errors && errors.length > 0) {
                  this.setState({
                    ownerErrorMessage: errors[0],
                    ownerInfoSaved: false,
                  });
                  DataLayerUtils.formErrorEvent("create an account", "owner info", errors[0]);
                }
                AppUtils.focusOnDiv("rm-top");
                LoadMask.hidePreloader(this.refs.ownerReg);
              });
          }
        );
      }
    };
  };

  getMicrochipProviderName = () => {
    for (let optionObj of this.state.chipProviders) {
      if (`${optionObj.Key}` === `${this.state.microchipProvider}`) {
        return optionObj.Value;
      }
    }

    return "";
  };

  savePetRegistrationInfo = (isFutureDate) => {
    const mcName = this.getMicrochipProviderName(); 

    if(isFutureDate){
      this.setState({
        savePetErrorMessage: "Future date for DOB of pet is not allowed!",
      });
      AppUtils.focusOnDiv("rm-top");
      return;
    }

    const petInfo = {
      microChipNumber: this.state.microChipNumber,
      microchipProvider: parseInt(this.state.microchipProvider),
      microchipProviderName: mcName,
      animalName: this.state.petName,
      species: this.state.petType,
      gender: this.state.petGender,
      breedType: this.state.breedType,
      breed: this.state.petBreed,
      breedName: this.state.petBreedLabel,
      color: this.state.petColor,
      colorName: this.state.colorLabel,
      spayedNeutered: this.state.isSprayed === "Yes" ? true : false,
      petBirthMonth: parseInt(this.state.birthMonth),
      petBirthYear: parseInt(this.state.birthYear),
      clinicName: this.state.clinicInfo,
      clinicNameText: this.state.clinicInfoLabel,
      contactConsent: this.state.isConsent === "Yes" ? true : false,
      newMphRegistrationId: this.state.newMphRegistrationId,
    };

    LoadMask.showPreloader(this.refs.petReg);
    axios
      .processPost(`${ApiConstants.URL_MICRO_SAVE_PET_INFO}`, null, petInfo)
      .then((response) => {
        LoadMask.hidePreloader(this.refs.petReg);
        this.setState({
          isPetInfoSubmitted: true,
        });
        this.clickNext();
        DataLayerUtils.createAccountEvent(
          this.props.location.state.type,
          'submit pet info',
          'organic',
          this.state.microChipNumber
        )
      })
      .catch((error) => {
        LoadMask.hidePreloader(this.refs.petReg);
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            savePetErrorMessage: errors[0],
          });
          DataLayerUtils.formErrorEvent("create an account", "pet info", errors[0]);
        }
        AppUtils.focusOnDiv("rm-top");
      });
  };

  clickNext = () => {
    this.setState((prevState) => {
      return {
        step: prevState.step + 1,
        savePetErrorMessage: "",
        ownerErrorMessage: "",
      };
    });
  };



  clickBack = () => {
    this.setState((prevState) => {
      return {
        step: prevState.step - 1,
        savePetErrorMessage: "",
        ownerErrorMessage: "",
      };
    });
  };

  handleChanges = (event, name) => {
    if (name === "firstName" || name === "lastName") {
      if(AppUtils.isAlphaNumeric(event.target.value) || event.keyCode === 8 || event.target.value === ""){
        this.setState({
          [name]: event.target.value,
        });
      }
      return;
    }
    if (name === "infoStart") {
      this.setState({
        infoStart: event,
      });
      return;
    }
    if (name === "state") {
      this.setState({
        state: event.label,
      });
      return;
    }

    if (name === "petBreed") {
      this.setState({
        petBreed: event.value,
        petBreedLabel: event.label,
      });
      return;
    }

    if (name === "petColor") {
      this.setState({
        colorLabel: event.label,
        petColor: event.value,
      });
      return;
    }

    if (name === "clinicInfo") {
      this.setState({
        clinicInfoLabel: event.label,
        clinicInfo: event.value,
      });
      return;
    }

    if (name === "microchipProvider") {
      this.setState(
        {
          microchipProvider: event.target.value,
        },
        () => {
          this.setState({
            microchipProviderName: this.getMicrochipProviderName(),
          });
        }
      );
    }

    if (name === 'country') {
      this.setState({
        zipCode: "",
        street: "",
        city: "",
        state: "",
      })
    }

    const fields = [
      "petGender",
      "petType",
      "isSprayed",
      "isConsent",
      "breedType",
    ];
    if (fields.includes(name)) {
      this.setState({
        [name]: event,
      });
      return;
    }
    this.setState({
      [name]: event.target.value,
    });
  };

  onJumpPage = (stepNumber) => {
    this.setState({
      step: stepNumber,
    });
  };
  onEditButtonClicked = (stepNumber) => {
    if (stepNumber === 1) {
      this.setState({
        isShowCancelStep1: true,
      });
    }

    if (stepNumber === 2) {
      this.setState({
        isShowCancelStep2: true,
      });
    }

    this.setState({
      step: stepNumber,
      showSteps: false,
      isEditClicked: true,
    });
  };

  getMicrochipListByCountry(country) {
    axios
      .processGet(`${ApiConstants.URL_MICRO_CHIP_COLORS_LIST}/${country}`)
      .then((response) => {
        if (country.toLowerCase() === "us") {
          this.setState({
            usColorList: response.data,
          });
        }
        if (country.toLowerCase() === "ca") {
          this.setState({
            canadaColorList: response.data,
          });
        }
      });
    LoadMask.showPreloader(this.refs.regRef);

    axios
      .processGet(`${ApiConstants.URL_MICRO_CHIP_CLINIC_LIST}/${country}`)
      .then((response) => {
        if (country.toLowerCase() === "us") {
          this.setState({
            usClinicList: response.data,
          });
        }
        if (country.toLowerCase() === "ca") {
          this.setState({
            canadaClinicList: response.data,
          });
        }
        LoadMask.hidePreloader(this.refs.regRef);
      })
      .catch((err) => {
        LoadMask.hidePreloader(this.refs.regRef);
        this.getMicrochipListByCountry(country);
      });
  }

  getBreedsList = () => {
    if (
      this.state.country.length > 0 &&
      this.state.petType.length > 0 &&
      this.state.breedType.length > 0
    ) {
      const payload = {
        species: this.state.petType,
        breedType: this.state.breedType,
        country: this.state.country,
      };

      axios
        .processPost(`${ApiConstants.URL_MICRO_LOAD_PET_LIST}`, null, payload)
        .then((response) => {
          this.setState({
            breedsList: response.data,
          });
        })
        .catch((error) => {});
    }
  };
  componentDidMount() {
    this.getMicrochipListByCountry("ca");
    this.getMicrochipListByCountry("us");

    if(
      this.props.location.state?.showOwnerInfoPage &&
      this.state.step === 0
    ){
      this.clickNext();
    }
  }
  
  getEmailValidationStatus() {
    if (AppUtils.isEmptyString(this.state.email)) {
      return {
        showError: true,
        errorMessage: "Please fill out your email address before continuing.",
      };
    }

    if (
      this.state.email.length > 0 &&
      !AppUtils.isValidEmail(this.state.email)
    ) {
      return {
        showError: true,
        errorMessage: "Please enter valid email address.",
      };
    }

    return {
      showError: false,
      errorMessage: "",
    };
  }
  // Called By review page.
  clickOnSubmit = () => {
    axios
      .processGet(
        `${ApiConstants.URL_MICRO_SAVE_ADDITIONAL_INFO}/0/${this.state.newMphRegistrationId}`,
        null
      )
      .then((response) => {
        this.clickNext();
        localStorage.setItem("firstName", this.state.firstName);
      })
      .catch((error) => {
        if (error) {
          this.setState({
            reviewPageError: "Error while saving review details",
          });
          DataLayerUtils.formErrorEvent("create an account", "review", "Error while saving review details");
          AppUtils.focusOnDiv("rm-top");
        }
      });
  };

  componentDidUpdate = (prevProps, prevState) => {
    if (
      this.state.country !== prevState.country ||
      this.state.petType !== prevState.petType ||
      this.state.breedType !== prevState.breedType
    )
      this.getBreedsList();
  };

  registerMicrochip = () => {
    const microchipNumber = this.state.microChipNumber;
    axios
      .processGet(
        `${ApiConstants.URL_MICROCHIP_NUMBER_VALIDATION}${microchipNumber}`,
        this.regValidRef
      )
      .then((response) => {
        this.setState(
          {
            chipProviders: response.data,
            microchipProvider: response.data[0]["Key"],
          },
          () => {
            const microchipProviderName = this.getMicrochipProviderName();
            this.setState({
              microchipProviderName,
            });
          }
        );
        LoadMask.hidePreloader(this.refs.regRef);
        DataLayerUtils.createAccountEvent(
          'microchip',
          'submit email',
          'organic',
          this.state.microChipNumber
        )
        this.clickNext();
      })
      .catch((error) => {
        let errors = error?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            chipValidationError: errors[0],
          });
          DataLayerUtils.formErrorEvent("create an account", "microchip", errors[0]);
        }
        LoadMask.hidePreloader(this.refs.regRef);
      });
  };

  emailSearchDB = () => {
    const emailStatus = this.getEmailValidationStatus();
    this.setState({
      isShowEmailError:emailStatus.showError,
      emailErrorMessage:emailStatus.errorMessage
    })
    if(emailStatus.showError){
      return;
    }

    const email = this.state.email;
    LoadMask.showPreloader(this.refs.regRef);
    axios
      .processGet(
        `${ApiConstants.URL_EMAIL_ADDRESS_UNIQUENESS_VALIDATION}${email}`,
        null
      )
      .then((response) => {
        this.registerMicrochip();
      })
      .catch((err) => {
        let errors = err?.response?.data?.errors;
        if (errors && errors.length > 0) {
          this.setState({
            isShowEmailError:true,
            emailErrorMessage: errors[0],
          });
          DataLayerUtils.formErrorEvent("create an account", "email", errors[0]);
        }
        LoadMask.hidePreloader(this.refs.regRef);
      });
  }

  getEmailComponent = () => {
    return (
      <TwoColumnContent
        leftColumn={
          <div ref="regRef">
            <RmStep1Card
              errorMessage={this.state.chipValidationError}
              backToLinkProps={{
                href: "auth/signup/step1?type=microchip",
                children: "Back to Register page",
              }}
              loginLinkProps={{
                href: "auth/login",
              }}
              pageTitle="Create an Account"
              microchipNumberInputFieldProps={{
                value: this.props.location.state.microchipNumber,
                disabled: true,
                helper: (
                  <InputHelperWithTooltip
                    helperContent="How do I find my pet's microchip number?"
                    tooltipContent="You'll find the microchip number on your pet's adaption form. Most numbers start with 982,985 or 60 and can be anywhere from 
                8 to 15 digits long. Some numbers may also start with 0, 1 8 or 977, or may include letters or characters. Have questions? Call us at 1-866-597-2424"
                  />
                ),
              }}
              emailInputFieldProps={{
                value: this.state.email,
                name: "email",
                id: "email",
                placeholder: "Example: yourname@email.com",
                error: this.state.isShowEmailError,
                hint: this.state.emailErrorMessage,
                onChange: (event) => {
                  this.handleChanges(event, "email");
                },
              }}
              onFormSubmit={() => {
                this.emailSearchDB();
              }}
            ></RmStep1Card>
          </div>
        }
        rightColumn={<RmStep1InfoCard></RmStep1InfoCard>}
      ></TwoColumnContent>
    )
  }

  getComponents = () => {
    const { step } = this.state;
    const formValues = { ...this.state };
    switch (step) {
      case 0:
        return (
          <>
            {this.getEmailComponent()}
          </>
        )
      case 1:
        return (
          <div ref="ownerReg">
            <RegistrationStep1
              clickNext={this.clickNext}
              clickBack={this.clickBack}
              saveOwnerRegistrationInfo={this.saveOwnerRegistrationInfo}
              formValues={formValues}
              type={this.props.location.state.type}
              existingPhoneNumbers={this.props.location.state.phoneNumbers}
              handleChanges={this.handleChanges}
              updateEmergencyContacts={(contacts) => this.updateEmergencyContacts(contacts)}
              updatePhoneNumbers={(numbers) => this.updatePhoneNumbers(numbers)}
              onJumpPage={this.onJumpPage}
            ></RegistrationStep1>
          </div>
        );
      case 2:
        return (
          <div ref="petReg">
            <RegistrationStep2
              clickNext={this.clickNext}
              clickBack={this.clickBack}
              formValues={formValues}
              type={this.props.location.state.type}
              savePetRegistrationInfo={this.savePetRegistrationInfo}
              handleChanges={this.handleChanges}
              onJumpPage={this.onJumpPage}
            ></RegistrationStep2>
          </div>
        );
      case 3:
        return (
          <RegistrationReview
            formValues={formValues}
            type={this.props.location.state.type}
            clickOnSubmit={this.clickOnSubmit}
            onEditButtonClicked={this.onEditButtonClicked}
            onJumpPage={this.onJumpPage}
          ></RegistrationReview>
        );
      case 4:
        return (
          <RegistrationSuccess
            formValues={formValues}
            type={this.props.location.state.type}
          ></RegistrationSuccess>
        );
      default:
        break;
    }
  };

  render() {
    return (
      <React.Fragment>
        <RmPageHeader
          className={
            FeatureFlagUtils.shouldAlterLogoSize() ? "custom-logo-size" : ""
          }
          onLogoClick={() => {
            this.props.history.push("/auth/login");
          }}
        ></RmPageHeader>
        <PageContent>
          <div id="rm-top">{this.getComponents()}</div>
        </PageContent>
        <RmPageFooter
          aboutUsButtonProps={{
            style: {
              cursor: "pointer",
            },
            onClick: () => {
              window.open("https://www.24petwatch.com/about-us");
            },
          }}
          contactUsButtonProps={{
            style: {
              cursor: "pointer",
            },
            onClick: () => {
              window.open("https://www.24petwatch.com/contact-us");
            },
          }}
        ></RmPageFooter>
      </React.Fragment>
    );
  }
}

export default RegisterMicrochipContainer;
